export class Parametro {
    static readonly DETALLES_PRIVADOS: string = 'DETALLES_PRIVADOS';
    static readonly SII_ACTIVADO: string = 'SII_ACTIVADO';
    static readonly CONCEPTO_ACTIVADO = 'CONCEPTO_ACTIVADO';
    static readonly TIPO_AGENDAMIENTO: string = 'TIPO_AGENDAMIENTO';
    static readonly DURACION_REUNION: string = 'DURACION_REUNION';
    static readonly DESCARGA_AUTOMATICA: string = 'DESCARGA_AUTOMATICA';
    static readonly FORMATO_PRECIO_REVISION: string = 'FORMATO_PRECIO_REVISION';
    static readonly FORMATO_TAMANO_REVISION: string = 'FORMATO_TAMANO_REVISION';
    static readonly IVA: string = 'IVA';
    static readonly UPDATE_PRECIO_COMPRA_EXACTO: string = 'UPDATE_PRECIO_COMPRA_EXACTO';
    static readonly PERMITIR_PRECIOS_PDF_CLIENTE_APP: string = 'PERMITIR_PRECIOS_PDF_CLIENTE_APP';
    static readonly SERVICIOS_EN_VENTA: string = 'SERVICIOS_EN_VENTA';
    static readonly OPERADOR_EDITA_VENTAS: string = 'OPERADOR_EDITA_VENTAS';
    static readonly ASIGNA_FOLIO_VACIO: string = 'ASIGNA_FOLIO_VACIO';
    static readonly RECORDATORIO_DEUDAS_CLIENTE: string = 'RECORDATORIO_DEUDAS_CLIENTE';
    static readonly METODO_PAGO_POR_DEFECTO_OT: string = 'METODO_PAGO_POR_DEFECTO_OT';
    
    static readonly TIPO_AGENDAMIENTO_DEFAULT: string = '2';
    static readonly DURACION_REUNION_DEFAULT: string = '30';
    static readonly FORMATO_TAMANO_REVISION_DEFAULT: string = '1';
    static readonly FORMATO_PRECIO_REVISION_DEFAULT: string = '1';
    static readonly DESCARGA_AUTOMATICA_DEFAULT: string = '1';
    static readonly PERMITIR_PRECIOS_PDF_CLIENTE_APP_DEFAULT: string = '1';
    static readonly OPERADOR_EDITA_VENTAS_DEFAULT: boolean = true;
    static readonly ASIGNA_FOLIO_VACIO_DEFAULT: boolean = false;
    static readonly RECORDATORIO_DEUDAS_CLIENTE_DEFAULT: string = '7';
    static readonly METODO_PAGO_POR_DEFECTO_OT_DEFAULT: string = '1';
}